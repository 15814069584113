/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/slick-carousel@1.8.1/slick/slick.min.css
 * - /npm/slick-carousel@1.6.0/slick/slick-theme.css
 * - /npm/hamburgers@0.9.3/dist/hamburgers.min.css
 * - /npm/bootstrap@4.0.0/dist/css/bootstrap.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
